/**
 * Made with <3 by Yann Thoraval
 */
import { Category, CategoryAdapter } from '../models/category.model';
import { CustomHttpClient } from '../helpers/custom-http-client';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {

  private readonly path = 'categories';

  public constructor(
    private http: CustomHttpClient,
    private categoryAdapter: CategoryAdapter
  ) { }

  public index(options: any = null): Observable<Category[]>
  {
    return this.http.get(this.path, options).pipe(
      map(data => data.data.map(item => this.categoryAdapter.adapt(item)))
    );
  }

  public show(slug: string, options: any = null): Observable<Category>
  {
    const path = this.path + '/' + slug;

    return this.http.get(path , options).pipe(
      map(data => this.categoryAdapter.adapt(data.data))
    );
  }
}
