/**
 * Made with <3 by Yann Thoraval
 */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from '../../../services/category.service';
import { Category } from '../../../models/category.model';
import { Album } from '../../../models/album.model';
import { Photo } from '../../../models/photo.model';
import { Video } from '../../../models/video.model';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  private readonly DEFAULT_SLUG = 'default';

  public category: Category;
  public item: Album|Photo|Video;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private categoryService: CategoryService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const slug = params.get('categorySlug');

      this.loadCategories(slug ? slug : this.DEFAULT_SLUG);
    });
  }

  private loadCategories(slug: string) {
    this.category = null;

    this.categoryService.show(slug).subscribe(data => {
      this.category = data;
    }, error => {
      console.error(error);
    });
  }

  onSelect(item: any) {
    switch (item.constructor.name) {
      case 'Album':
        this.router.navigate(['/albums', item.slug]);
        break;
      case 'Photo':
      case 'Video':
        this.item = item;
        break;
    }
  }

}
