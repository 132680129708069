/**
 * Made with <3 by Yann Thoraval
 */
import { BrowserModule } from '@angular/platform-browser';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTumblr } from '@fortawesome/free-brands-svg-icons';
import { faVimeo } from '@fortawesome/free-brands-svg-icons';

import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { AppComponent } from './app.component';
import { SlideshowModule } from 'ng-simple-slideshow';

import { AlbumComponent } from '../components/pages/album/album.component';
import { InfoComponent } from '../components/pages/info/info.component';
import { CategoryComponent } from '../components/pages/category/category.component';
import { FooterComponent } from '../components/common/footer/footer.component';
import { GalleryComponent } from '../components/common/gallery/gallery.component';
import { HeaderComponent } from '../components/common/header/header.component';
import { LightboxComponent } from '../components/pages/lightbox/lightbox.component';
import { MenuComponent } from '../components/common/menu/menu.component';
import { ViewerComponent } from '../components/common/viewer/viewer.component';

import { routes } from '../resources/routes';

@NgModule({
  declarations: [
    AppComponent,

    AlbumComponent,
    InfoComponent,
    FooterComponent,
    GalleryComponent,
    HeaderComponent,
    LightboxComponent,
    MenuComponent,
    CategoryComponent,
    ViewerComponent,
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    SlideshowModule,
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faHeart);
    library.addIcons(faSpinner);
    library.addIcons(faTimes);

    // @ts-ignore-start
    library.addIcons(faFacebook);
    // @ts-ignore-start
    library.addIcons(faInstagram);
    // @ts-ignore-start
    library.addIcons(faTumblr);
    // @ts-ignore-start
    library.addIcons(faVimeo);
  }
}
