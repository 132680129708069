/**
 * Made with <3 by Yann Thoraval
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Album } from '../../../models/album.model';
import { Photo } from '../../../models/photo.model';
import { Video } from '../../../models/video.model';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  @Input() items: Array<Album|Photo|Video>;
  @Input() hasTwoColumns = false;
  @Output() selected = new EventEmitter<Album|Photo|Video>();

  constructor() {
  }

  ngOnInit() {
  }

  select(item: Album|Photo|Video): boolean {
    this.selected.emit(item);
    return false;
  }
}
