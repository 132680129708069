/**
 * Made with <3 by Yann Thoraval
 */
import { Adapter } from '../helpers/adapter';
import { Injectable } from '@angular/core';
import {Album} from './album.model';

export class Photo {
  public album: Album;

  public constructor(
    public id: number,
    public order: number,
    public url: string
  ) {
  }
}


@Injectable({
  providedIn: 'root',
})
export class PhotoAdapter implements Adapter<Photo> {
  adapt(data: any, album?: Album): Photo {
    const photo = new Photo(
      data.id,
      data.attributes.order,
      data.attributes.url
    );

    if (album) {
      photo.album = album;
    }

    return photo;
  }
}
