/**
 * Made with <3 by Yann Thoraval
 */
import { Adapter } from '../helpers/adapter';
import { Injectable } from '@angular/core';
import { Category, CategoryAdapter } from './category.model';
import {Video, VideoAdapter} from './video.model';
import {Album, AlbumAdapter} from './album.model';

export class CategoryItem {
  public category: Category;
  public itemable: Album|Video;

  public constructor(
    public id: number,
    public order: number
  ) {
  }
}


@Injectable({
  providedIn: 'root',
})
export class CategoryItemAdapter implements Adapter<CategoryItem> {
  adapt(data: any): CategoryItem {
    const item =  new CategoryItem(
      data.id,
      data.attributes.order
    );

    if (data.relationships.category) {
      item.category = new CategoryAdapter().adapt(data.relationships.category);
    }

    if (data.relationships.itemable) {
      if (data.relationships.itemable.type === 'album') {
        item.itemable = new AlbumAdapter().adapt(data.relationships.itemable);
      } else if (data.relationships.itemable.type === 'video') {
        item.itemable = new VideoAdapter().adapt(data.relationships.itemable);
      }
    }

    return item;
  }
}
