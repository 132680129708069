/**
 * Made with <3 by Yann Thoraval
 */
import { Component, EventEmitter, HostListener, Input, OnChanges, Output, ViewChild } from '@angular/core';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnChanges {

  @Input() item: any;
  @Output() closed = new EventEmitter<void>();
  @ViewChild('slideshow', {static: false}) slideshow: any;

  public collection: Array<{url: string, caption: string, loaded: boolean}>;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {

    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.slideNext();
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.slidePrev();
    }
  }

  constructor() { }

  ngOnChanges(): void {
    this.collection = [];

    if (this.item && this.item.constructor.name === 'Photo') {
      this.buildCollection();
    }
  }

  close() {
    this.item = null;
    this.closed.emit();
  }

  private buildCollection() {
    const position = this.item.album.photos.indexOf(this.item);
    const length = this.item.album.photos.length;

    for (let i = 0; i < length; i++) {
      const photo = this.item.album.photos[(position + i) % length];

      this.collection.push(
        {
          url: photo.url,
          caption: photo.name,
          loaded: false
        }
      );
    }
  }

  slideNext() {
    if (this.collection.length) {
      this.slideshow.onSlide(1);
    }
  }

  slidePrev() {
    if (this.collection.length) {
      this.slideshow.onSlide(-1);
    }
  }
}
