/**
 * Made with <3 by Yann Thoraval
 */
import { AlbumComponent } from '../components/pages/album/album.component';
import { InfoComponent } from '../components/pages/info/info.component';
import { CategoryComponent } from '../components/pages/category/category.component';
import { LightboxComponent } from '../components/pages/lightbox/lightbox.component';
import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    component: CategoryComponent
  },
  {
    path: 'categories/:categorySlug',
    component: CategoryComponent
  },
  {
    path: 'albums/:albumSlug',
    component: AlbumComponent
  },
  {
    path: 'albums/:albumSlug/photos/:photoSlug',
    component: AlbumComponent
  },
  {
    path: 'videos/:videoSlug',
    component: CategoryComponent
  },
  {
    path: 'info',
    component: InfoComponent
  },
  {
    path: 'lightbox',
    component: LightboxComponent
  },
];
