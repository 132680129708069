/**
 * Made with <3 by Yann Thoraval
 */
import { CustomHttpClient } from '../helpers/custom-http-client';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Album, AlbumAdapter } from '../models/album.model';

@Injectable({
  providedIn: 'root',
})
export class AlbumService {

  private readonly path = 'albums';

  public constructor(
    private http: CustomHttpClient,
    private albumAdapter: AlbumAdapter
  ) { }

  public show(slug: string, options: any = null): Observable<Album>
  {
    const path = this.path + '/' + slug;

    return this.http.get(path , options).pipe(
      map(data => this.albumAdapter.adapt(data.data))
    );
  }
}
