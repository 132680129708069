/**
 * Made with <3 by Yann Thoraval
 */
import { Adapter } from '../helpers/adapter';
import { Injectable } from '@angular/core';
import { CategoryItem, CategoryItemAdapter } from './category_item.model';
import { Album } from './album.model';
import { Video } from './video.model';

export class Category {
  /**
   *
   */
  public categoryItems: CategoryItem[];

  public constructor(
    public id: number,
    public name: string,
    public slug: string,
    public order: number
  ) {
    this.categoryItems = [];
  }

  get items(): Array<Album|Video> {
    const items = [];

    for(const item of this.categoryItems) {
      if (item.itemable) {
        items.push(item.itemable);
      }
    }

    return items;
  }
}


@Injectable({
  providedIn: 'root',
})
export class CategoryAdapter implements Adapter<Category> {
  adapt(data: any): Category {
    const category =  new Category(
      data.id,
      data.attributes.name,
      data.attributes.slug,
      data.attributes.order
    );

    if (data.relationships.items) {
      for (const item of data.relationships.items.data) {
        category.categoryItems.push(new CategoryItemAdapter().adapt(item));
      }
    }

    return category;
  }
}
