/**
 * Made with <3 by Yann Thoraval
 */
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Category } from '../../../models/category.model';
import { CategoryService } from '../../../services/category.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public categories: Array<Category>;

  constructor(
    private route: ActivatedRoute,
    private categoryService: CategoryService
  ) {
  }

  ngOnInit() {
    this.categoryService.index().subscribe(data => {
      this.categories = data;
    }, error => {
      console.error(error);
    });
  }

}
