/**
 * Made with <3 by Yann Thoraval
 */
import { Adapter } from '../helpers/adapter';
import { Injectable } from '@angular/core';
import { CategoryItem, CategoryItemAdapter } from './category_item.model';
import {Category, CategoryAdapter} from './category.model';

export class Video {
  public category: Category;
  public categoryItem: CategoryItem;

  public constructor(
    public id: number,
    public title: string,
    public subtitle: string,
    public slug: string,
    public service: string,
    public url: string,
    public preview: string
  ) {
  }
}


@Injectable({
  providedIn: 'root',
})
export class VideoAdapter implements Adapter<Video> {
  adapt(data: any): Video {
    const video =  new Video(
      data.id,
      data.attributes.title,
      data.attributes.subtitle,
      data.attributes.slug,
      data.attributes.service,
      data.attributes.url,
      data.attributes.preview
    );

    if (data.relationships.category) {
      video.category = new CategoryAdapter().adapt(data.relationships.category);
    }

    if (data.relationships.category_item) {
      video.categoryItem = new CategoryItemAdapter().adapt(data.relationships.category_item);
    }

    return video;
  }
}
