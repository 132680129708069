/**
 * Made with <3 by Yann Thoraval
 */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlbumService } from '../../../services/album.service';
import { Album } from '../../../models/album.model';
import { Photo } from '../../../models/photo.model';
import { Video } from '../../../models/video.model';

@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.scss']
})
export class AlbumComponent implements OnInit {

  public album: Album;
  public item: Album|Photo|Video;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private albumService: AlbumService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const slug = params.get('albumSlug');

      if (slug) {
        this.loadAlbum(slug);
      }
    });
  }

  private loadAlbum(slug: string) {
    this.album = null;

    this.albumService.show(slug).subscribe(data => {
      this.album = data;
    }, error => {
      console.error(error);
    });
  }

  onSelect(item: any) {
    switch (item.constructor.name) {
      case 'Album':
        this.router.navigate(['/albums', item.slug]);
        break;
      case 'Photo':
        this.item = item;
        break;
      case 'Video':
        this.item = item;
        break;
    }
  }

  onClose() {
    this.item = null;
  }

  backward() {
    window.history.back();
  }

}
