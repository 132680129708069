/**
 * Made with <3 by Yann Thoraval
 */
import { Adapter } from '../helpers/adapter';
import { Injectable } from '@angular/core';
import { Photo, PhotoAdapter } from './photo.model';

export class Album {
  public photos: Photo[];

  public constructor(
    public id: number,
    public title: string,
    public subtitle: string,
    public slug: string,
    public thumbnailUrl: string
  ) {
    this.photos = [];
  }
}


@Injectable({
  providedIn: 'root',
})
export class AlbumAdapter implements Adapter<Album> {
  adapt(data: any): Album {
    const album =  new Album(
      data.id,
      data.attributes.title,
      data.attributes.subtitle,
      data.attributes.slug,
      data.attributes.thumbnail_url
    );

    if (data.relationships.photos) {
      for (const photo of data.relationships.photos.data) {
        const photoModel = new PhotoAdapter().adapt(photo, album);
        album.photos.push(photoModel);
      }
    }

    return album;
  }
}
